export const usePadBottom = (size) => {
  switch (size) {
    case "xs":
      return "pb-[15px] sm:pb-[25px] xl:pb-[50px]";
    case "small":
      return "pb-[15px] sm:pb-[25px] xl:pb-[50px]";
    case "medium":
      return "pb-[60px] md:pb-[75px] xl:pb-[100px]";
    case "large":
      return "pb-[140px] md:pb-[150px] xl:pb-[200px]";
    default:
      return null;
  }
};
