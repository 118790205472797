import React from "react";

import Logo from "../../svg/logo.svg";

import { useSiteConfig } from "../../GraphQl/useSiteConfig";
import { NavLink } from "./navLink";

export const Footer = () => {
  const { mainNav } = useSiteConfig();

  // get current year
  const year = new Date().getFullYear();

  return (
    <footer className=" px-10 pb-12 md:pt-4  text-gray-400 flex flex-col text-center md:flex-row md:justify-between md:text-left ">
      <div className="hidden md:block w-48 h-auto">
        <Logo />
      </div>
      <div className="mt-8 md:mt-0 md:text-right">
        <div className=" md:hidden w-56 h-auto mt-20 mb-10 mx-auto">
          <Logo className="w-full h-full" />
        </div>
        <p className="mt-8 md:mt-4 text-[14px] justify-self-end">
          &copy; {year} Knowsy.
        </p>
      </div>
    </footer>
  );
};
